
.projects-boxes {
    width: 700px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 740px;
    flex-wrap: wrap;
    justify-content: space-between;
}



@media screen and (min-width: 1000px) {
    .projects-boxes  {
        margin: 30px auto auto auto;
        width: auto;
        max-width: 1100px;
        min-width: 1000px;
        flex-direction: row;
        height: 740px;
    }
}

@media screen and (max-width: 1000px) {
    .projects-boxes  {
        margin: -10px auto auto auto;
        width: 350px;
        flex-direction: column;
        height: 1400px;
    }
}

.projects-boxes h1 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.description p {
    margin-top: 20px;
}

.text {
    margin-left: 20px;
    margin-right: 20px;
}
