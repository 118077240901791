.box {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    width: 330px;
    height: 330px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255,255,255, .3);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.description p {
    margin-top: 20px;
}

.box h1 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.icon-and-info {
    width: 330px;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.svg-inline--fa.fa-calendar-alt.fa-w-14 {
    margin-top: 4px;
    width: 20px;
}
.svg-inline--fa.fa-user-tag.fa-w-20 {
    margin-top: 7px;
    width: 20px;
}
.svg-inline--fa.fa-info-circle.fa-w-16 {
    margin-top: 7px;
    width: 20px;
}

.icon-and-info .icon {
    margin-left: 20px;
}

.icon-and-info p {
    font-family: "NunitoRegular";
    width: 240px;
    text-align: left;
    margin-right: 20px;
    margin-left: 0;
}
