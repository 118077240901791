
.icon-and-information {
    display: flex;
    flex-direction: row;
}

@media screen and (min-width: 1000px) {
    .contact-box {
        margin: 30px auto auto;
        width: 600px;
        height: 350px;
    }
    .bbox {
        width: 600px;
        height: 350px;
        flex-direction: row;
    }
    .icon-and-information p {
        margin-right: 20px;
        margin-left: 20px;
        font-weight: bold;
    }
    .information {
        margin-top: 30px;
    }
    .information h1 {
        text-align: left;
        margin-left: 50px;
    }
    .icon-and-information {
        margin-left: 50px;
    }
}

@media screen and (max-width: 1000px) {
    .contact-box {
        margin: 0 auto auto;
        width: 350px;
        height: 600px;
    }
    .bbox {
        width: 350px;
        height: 600px;
        flex-direction: column;
    }
    .icon-and-information p {
        margin-right: 35px;
        margin-left: 20px;
        font-weight: bold;
    }
    .information h1 {
        text-align: center;
    }
    .icon-and-information {
        margin-left: 30px;
    }
}

img.icon {
    width: 25px;
    height: 25px;
}

.icon-and-information p {
    width: 300px;
    text-align: left;
    margin-top: 10px;
    margin-right: 20px;
}

.contact-box {
    display: flex;
    align-items: center;
    text-align: center;
}

.bbox {
    display: flex;
}

.contact h1 {
    font-size: 60px;
    font-weight: bold;
}

.text {
    font-family: "NunitoRegular";
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.marcus {
    margin-bottom: 40px;
    margin-top: 40px;
    width: 240px;
    height: 240px;
    border-radius: 50%;
}
