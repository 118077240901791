
.content-boxes {
    display: flex;
    align-items: center;
    text-align: center;
}

@media screen and (min-width: 1000px) {
    .content-boxes  {
        margin: 50px auto auto;
        width: 800px;
        flex-direction: row;
        height: 600px;
    }
    .text {
        margin-left: 40px !important;
        margin-right: 10px !important;
    }
    .text p {
        font-weight: 400;
    }
    .text p {
        font-family: "NunitoRegular";
    }
}

@media screen and (max-width: 1000px) {
    .content-boxes  {
        margin: 0 auto auto;
        width: 375px;
        flex-direction: column;
        height: 1200px;
    }
    .text {
        margin-left: 40px !important;
        margin-right: 20px !important;
    }
    .text p {
        font-family: "NunitoRegular";
    }
}

.strike {
    border-top: 4px solid #f6f7f1;
    width: 70px;
    margin: 0 auto 0;
}

.left-box {
    width: 400px;
    height: 650px;
    display: flex;
    flex-direction: column;
}

.left-box h1 {
    margin-top: 10px;
}

.contact{
    margin-left: 75px;
    width: 250px;
    height: 105px;
    bottom: 0;
    margin-top: 0;
    display: flex;
    flex-direction: row;
}

.icon {
    margin: auto;
    width: 32px;
    height: 32px;
}

.marcus {
    margin: 80px auto auto;
    width: 240px;
    height: 240px;
    border-radius: 50%;
}

.right-box {
    text-align: left;
    width: 400px;
    height: 650px;
}

.hello h1 {
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 0;
}

.hello {
    margin-left: 20px;
    margin-right: 20px;
}

.who-what h4 {
    text-align: left;
    margin-top: 0;
    margin-left: 40px;
    margin-bottom: 30px;
}

.buttons {
    margin: 20px 20px 35px;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}

.button-left {
    background-color: rgba(255,255,255, .3);
    color: black;
    padding:0.4em 2em;
    border:0.16em solid black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius:2em;
    margin-right: 8px;
}

.button-right {
    margin-left: 8px;
    background-color: rgba(255,255,255, .3);
    color: black;
    padding:0.4em 2em;
    border:0.16em solid black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius:2em;
}


.button-right:hover {
    background-color: rgba(0,0,0, .8);
    color: white;
    cursor: pointer;
}

.button-left:hover {
    background-color: rgba(0,0,0, .8);
    color: white;
    cursor: pointer;
}

.core-competence {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
}

.core-competence ul {
    width: 370px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.core-competence li {
    list-style: none;
    display: inline-block;
    width: calc(100% / 4);
    text-align: left;
}

ul {
    margin-left: -20px;
}
